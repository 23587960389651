ll<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('employees')"
                class="mr-4"
                style="cursor: pointer"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
              Criar funcionário
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col md="12">
          <v-form
            v-model="submitNewEmployee"
            ref="submitNewEmployee"
            @submit.prevent="submitEmployee(employee.id)"
          >
            <v-row justify="center">
              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  label="nome"
                  name="name"
                  outlined
                  v-model="name"
                  :rules="textRules"
                />
              </v-col>

              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  outlined
                  label="CPF"
                  name="cpf"
                  v-model="cpf"
                  v-mask="['###.###.###-##']"
                  :rules="textRules"
                />
              </v-col>

              <v-col lg="3" md="4" sm="12">
                <v-select
                  v-model="categoriesSelect"
                  :items="getCategoryNames"
                  item-text="desc"
                  item-value="id"
                  label="Categorias"
                  :rules="textRules"
                  outlined
                ></v-select>
              </v-col>

              <v-col sm="10" class="text-right">
                <v-btn
                  type="submit"
                  style="color: #fff; background-color: rgb(61, 17, 89)"
                >
                  <v-icon left>
                    mdi-check
                  </v-icon>
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import store from "@/store";
import { CATEGORIES_GET } from "@/store/actions/categories.type";
import { EMPLOYEE_CREATE } from "@/store/actions/employees.type";

import { CATEGORY_RESET_STATE } from "@/store/mutations/categories.type";
export default {
  name: "Employees",

  props: {
    id: {
      default: 0,
      type: Number,
      required: false
    }
  },

  directives: { mask },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(CATEGORY_RESET_STATE);
    await store.dispatch(CATEGORIES_GET);

    return next();
  },

  data: () => ({
    submitNewEmployee: false,
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    // v-model
    name: "",
    cpf: "",
    categoriesSelect: ""

    // items: [
    //   { value: 1, desc: "categoria 1" },
    //   { value: 2, desc: "categoria 2" },
    //   { value: 3, desc: "categoria 3" }
    // ]
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    submitEmployee(id) {
      console.log("submit new employee");
      if (this.$refs.submitNewEmployee.validate()) {
        this.inProgress = true;
        this.$store
          .dispatch(EMPLOYEE_CREATE)
          .then(res => {
            this.inProgress = false;
            this.bloqueiaEnvio = false;

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            if (res.data.id) {
              this.$router.push({
                name: "employee",
                params: { id: res.data.id }
              });
            } else {
              this.$router.push({
                name: "employee",
                params: { id: id }
              });
            }
          })
          .catch(({ res }) => {
            this.inProgress = false;
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar Beauty Maker.";
            console.log(res.data);
            if (typeof res.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar Funcionário: " + res.data.message;
            }
          });
      }
    }
  },

  computed: {
    ...mapGetters(["employee", "categories", "checkUsers", "snackbar"]),

    getCategoryNames() {
      const store = this.$store.getters.categories;
      const map = store.map(item => item.name);
      return map;
    }
  }
};
</script>
